import React,{useRef} from "react";
import "./JoinUs.css";
import emailjs from '@emailjs/browser'

const JoinUs = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_f3yjm58', 'template_45vjwss', form.current, '_OsQZYrnf7TBGrIhs')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
    <div className="JoinUs" id="JoinUs">
      <div className="left-j">
        <div>
          <span className="stroke-text">ready to </span>
          <span>level up</span>
        </div>

        <div>
          <span>your body </span>
          <span className="stroke-text">with us?</span>
        </div>
      </div>
      <div className="right-j">
            <form className="email-form" ref={form} onSubmit={sendEmail}>
                <input type="email" name="user_email" placeholder="Enter Your Email Address"/>
                <button className="btn btn-j">Join Us</button>
            </form>
      </div>
    </div>
  );
};

export default JoinUs;
