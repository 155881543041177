import React, { useState } from "react";
import "./Testimonials.css";
import t1 from "../../assets/t-image1.png";
import { testimonialsData } from "../../data/testimonialsData";
import leftArrow from "../../assets/leftArrow.png";
import rightArrow from "../../assets/rightArrow.png";
import { motion } from "framer-motion";

const Testimonials = () => {
  const [selected, setSelected] = useState(0);
  const transition = { type: "spring", duration: 3 };
  const SelectRight = () => {
    if (selected == testimonialsData.length - 1) {
      setSelected(0);
    } else {
      setSelected(selected + 1);
    }
  };

  const SelectLeft = () => {
    if (selected == 0) {
      setSelected(testimonialsData.length - 1);
    } else {
      setSelected(selected - 1);
    }
  };
  return (
    <div className="testimonials" id="testimonians">
      <div className="testimonial-left">
        <span>testimonials</span>
        <div>
          <span className="stroke-text">What they </span>
          <span>say about us</span>
        </div>

        <motion.div
          key={selected}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={transition}
        >
          <p>{testimonialsData[selected].review}</p>
        </motion.div>

        <div>
          <span>{testimonialsData[selected].name}</span>
          <span> - {testimonialsData[selected].status}</span>
        </div>
      </div>

      <div className="testimonial-right">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ...transition, duration: 2 }}
        ></motion.div>
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ ...transition, duration: 2 }}
        ></motion.div>
        <motion.img
          key={selected}
          initial={{ opacity: 0, x: 100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={transition}
          src={testimonialsData[selected].image}
          alt=""
        />
        <div className="arrows">
          <img src={leftArrow} alt="" onClick={SelectLeft} />
          <img src={rightArrow} alt="" onClick={SelectRight} />
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
